<template>
  <div class="group/cell w-full">
    <UDropdown
      :open="dropdownOpen"
      :items="items"
      :popper="{ placement: 'bottom-start', }"
      :ui="{ trigger: 'w-full', item: { active: 'bg-gray-100' } }"
      class="w-full"
      v-bind="$attrs"
      @click.prevent.exact.stop
      @update:open="dropdownOpen = $event"
    >
      <template #item="{ item }">
        <div class="flex items-center justify-between w-full">
          <div>{{ item.label }}</div>
          <UIcon v-if="item.label.toUpperCase() === selectedCalc" name="i-heroicons-check" :size="20" />
        </div>
      </template>
      <Tooltip :text="toString(result)" class="w-full">
        <template #default="{ getTextRef }">
          <div class="flex items-center justify-between w-full">
            <UButton
              color="white" variant="soft" :label="selectedCalc"
              @click.prevent.stop="dropdownOpen = true"
            >
              <template #trailing>
                <UIcon name="i-heroicons-chevron-down-20-solid" :size="16" class="group-hover/cell:block hidden" />
              </template>
            </UButton>
            <div :ref="getTextRef" class="text-xs font-medium leading-4 line-clamp-1 break-all mr-2">{{ result }}</div>
          </div>
        </template>
      </Tooltip>
    </UDropdown>
  </div>
</template>

<script setup lang="ts">
import { toString } from 'lodash-es'
import type { ColumnType } from '#board/types'

const props = defineProps({
  value: {
    type: Array<string>,
  },
  field: {
    type: Object as PropType<ColumnType>,
    required: true,
  },
})

const { calcColumn } = useBoardSharedState()
const dropdownOpen = ref(false)
const selectedCalc = computed(() => {
  return calcColumn.find((c) => c.key === props.field.key)?.operator ?? 'SUM'
})

const result = computed(
  () => {
    const preparedList = props.value?.map(Number).filter(Boolean) ?? []
    return getCalcResult(preparedList, selectedCalc.value)
  }
)

const items = computed(() => [
  [
    'None',
    'Sum',
    'Average',
    'Count',
    'Min',
    'Max'
  ].map(label => ({
    label,
    click: () => {
      if (label === 'None') {
        return onSetCalcColumn('Calculate')
      }

      return onSetCalcColumn(label.toUpperCase())
    }
  }))
])

const onSetCalcColumn = (operator: string) => {
  const existingCalcIndex = calcColumn.findIndex((c) => c.key === props.field.key)
  if (existingCalcIndex !== -1) {
    calcColumn[existingCalcIndex].operator = operator
  } else {
    const { key, label } = props.field
    calcColumn.push({ key, operator, name: label })
  }
}

onMounted(() => {
  onSetCalcColumn(selectedCalc.value)
})
</script>
